
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FloatingMenuEvents, FloatingMenuItem } from '@/models';
import { EventBus } from '@/utils';

@Component({
  name: 'ButtonType',
})
export default class ButtonType extends Vue {
  @Prop() data: FloatingMenuItem

  public buttonClicked() {
    const event = this.data.action as FloatingMenuEvents;
    const item = this.data;
    EventBus.$emit(event, item);
    this.$emit('change', { event, item });
  }
}
