
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import SimpleType from '@/components/FloatingMenu/FloatingMenuTypes/SimpleType.vue';
import LinkType from '@/components/FloatingMenu/FloatingMenuTypes/LinkType.vue';
import CheckboxType from '@/components/FloatingMenu/FloatingMenuTypes/CheckboxType.vue';
import SelectType from '@/components/FloatingMenu/FloatingMenuTypes/SelectType.vue';
import InputType from '@/components/FloatingMenu/FloatingMenuTypes/InputType.vue';
import ButtonType from '@/components/FloatingMenu/FloatingMenuTypes/ButtonType.vue';

import { FloatingMenuContent } from '@/models';

import WrapperType from './FloatingMenuTypes/WrapperType.vue';
import DividerType from './FloatingMenuTypes/DividerType.vue';
import SpinnerType from './FloatingMenuTypes/SpinnerType.vue';
import TextType from './FloatingMenuTypes/TextType.vue';

const {
  SIMPLE_TYPE,
  LINK_TYPE,
  CHECKBOX_TYPE,
  SELECT_TYPE,
  INPUT_TYPE,
  BUTTON_TYPE,
  WRAPPER_TYPE,
  DIVIDER_TYPE,
  SPINNER_TYPE,
  TEXT_TYPE,
} = FloatingMenuContent;

const menuContent: {
  [index: string]: VueConstructor;
} = {
  [SIMPLE_TYPE]: SimpleType,
  [LINK_TYPE]: LinkType,
  [CHECKBOX_TYPE]: CheckboxType,
  [SELECT_TYPE]: SelectType,
  [INPUT_TYPE]: InputType,
  [BUTTON_TYPE]: ButtonType,
  [WRAPPER_TYPE]: WrapperType,
  [DIVIDER_TYPE]: DividerType,
  [SPINNER_TYPE]: SpinnerType,
  [TEXT_TYPE]: TextType,
};

@Component({
  name: 'FloatingMenuFactory',
})
export default class FloatingMenuFactory extends Vue {
  public static getContent(content: FloatingMenuContent): VueConstructor {
    return menuContent[content];
  }
}
