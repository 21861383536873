
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { FloatingMenuItem } from '@/models';
import FloatingMenuFactory from '../FloatingMenuFactory.vue';

@Component({
  name: 'WrapperType',
})
export default class WrapperType extends Vue {
  @Prop() data: FloatingMenuItem[];

  public getContent(item: FloatingMenuItem): VueConstructor | null {
    return FloatingMenuFactory.getContent(item.type);
  }
}
