var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"check-type",class:{
    'check-type--dark': _vm.darkMode,
    'check-type--bordered': _vm.bordered,
    'check-type--on-header': _vm.onHeader,
  },on:{"click":function($event){return _vm.emitAction(!_vm.data.checked, _vm.data.id)}}},[_c('CheckboxInput',{attrs:{"checked":_vm.data.checked,"name":_vm.data.text,"id":_vm.data.id,"type_2":true},on:{"change":function($event){return _vm.emitAction(!_vm.data.checked, _vm.data.id)}}}),_c('span',{staticClass:"check-type__text",class:{
      'check-type__text--wrap': _vm.data.textWrap
    },style:({ textAlign: _vm.textAlign })},[_vm._v(" "+_vm._s(_vm.data.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }