
import {
  Component,
  Model,
  Prop,
  Vue,
} from 'vue-property-decorator';

import Checked from '@/assets/icons/Checked.svg';
import Unchecked from '@/assets/icons/Unchecked.svg';

@Component({
  name: 'CheckboxInput',
  components: {
    Checked,
    Unchecked,
  },
})
export default class CheckboxInput extends Vue {
  @Prop({}) name: string;
  @Prop({}) id: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) type_2: boolean;
  @Model('change', { type: Boolean }) checked!: boolean;

  $refs!: {
    checkboxInput: HTMLInputElement;
  };

  public handleCheckboxClick() {
    if (this.disabled) return;

    this.$refs.checkboxInput.click();
  }

  public output() {
    const value = this.$refs.checkboxInput.checked;
    this.$emit('change', value, this.id);
  }
}
