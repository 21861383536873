
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import AdminDefaultPhoto from '@/assets/icons/AdminDefaultPhoto.svg';

import { TipoDeAcesso } from '@/models/api';

@Component({
  name: 'Avatar',
  computed: {
    ...mapGetters('user', {
      getUserAccessType: 'getUserAccessType',
    }),
  },
  components: {
    AdminDefaultPhoto,
  },
})
export default class Avatar extends Vue {
  @Prop({}) public profileImage: string;
  public defaultImage = require('@/assets/img/no-profile-photo.png');
  public getUserAccessType!: TipoDeAcesso;

  public TipoDeAcesso = TipoDeAcesso;

  @Prop({ default: 24 }) public size: number;

  @Prop() profileName: string;
}
