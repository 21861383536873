
import { Component, Prop, Vue } from 'vue-property-decorator';

import { FloatingMenuEvents, FloatingMenuItem } from '@/models';
import { EventBus } from '@/utils';

@Component({
  name: 'SelectType',
})
export default class SelectType extends Vue {
  @Prop() data: FloatingMenuItem

  public emitSelection(item: FloatingMenuItem) {
    const emitItem = item;
    emitItem.selected = !item.selected;
    EventBus.$emit(item.action as string, emitItem);
    this.$emit('change', { event: item.action as FloatingMenuEvents, item });
  }
}
