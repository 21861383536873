
import { Component, Vue } from 'vue-property-decorator';

import Spinner from '@/components/shared/Spinner.vue';

@Component({
  name: 'SpinnerType',
  components: {
    Spinner,
  },
})
export default class SpinnerType extends Vue {}
