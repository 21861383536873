
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import {
  FloatingMenuEvents,
  FloatingMenuItem,
  FloatingMenuModel,
  FloatingMenuPosition,
} from '@/models';

import { EventBus } from '@/utils';

import FloatingMenuFactory from './FloatingMenuFactory.vue';

const { RIGHT, LEFT } = FloatingMenuPosition;
const { OPEN } = FloatingMenuEvents;

@Component({ name: 'FloatingMenu' })
export default class FloatingMenu extends Vue {
  @Prop() menu: FloatingMenuModel;

  private showItems = false;
  private menuContent: VueConstructor | null

  public RIGHT = RIGHT
  public LEFT = LEFT

  get show(): boolean {
    return this.showItems || this.menu.showMenu || false;
  }

  set show(s: boolean) {
    this.showItems = s;
  }

  mounted() {
    if (this.menu.openOnlyOne) this.subscribeToEvents();
  }

  beforeDestroy() {
    if (this.menu.openOnlyOne) this.unsubscribeToEvents();
  }

  private subscribeToEvents() {
    EventBus.$on(OPEN, this.closeOthers);
  }
  private unsubscribeToEvents() {
    EventBus.$off(OPEN, this.closeOthers);
  }

  public getContent(item: FloatingMenuItem): VueConstructor | null {
    return this.menu.data.length
      ? FloatingMenuFactory.getContent(item.type)
      : this.menuContent;
  }

  public openMenu() {
    if (this.menu.hover) this.show = true;
  }

  public closeMenu() {
    if (this.menu.hover) this.show = false;
  }

  public toggleMenu(hover: boolean, ev: PointerEvent) {
    if (hover) {
      ev.preventDefault();
      return;
    }

    this.show = !this.show;
    this.$emit('click', this.show);
    EventBus.$emit(OPEN, this.menu, this.show);
  }

  public emitChange(
    index: number,
    keyString: string,
    { event, item, value }: {event: FloatingMenuEvents; item?: FloatingMenuItem; value?: string},
  ) {
    this.$emit('change', {
      index, keyString, event, item, value,
    });
  }

  private closeOthers({ keyString }: FloatingMenuModel, open: boolean) {
    if (open && this.menu.openOnlyOne && (!keyString || keyString !== this.menu.keyString)) {
      this.show = false;
    }
    if (keyString === 'All') this.show = open;
  }
}
