
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FloatingMenuItem } from '@/models';

@Component({
  name: 'DividerType',
})
export default class DividerType extends Vue {
  @Prop() data: FloatingMenuItem
}
