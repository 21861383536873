
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FloatingMenuItem } from '@/models';

@Component({
  name: 'LinkType',
})
export default class LinkType extends Vue {
  @Prop() data: FloatingMenuItem
  @Prop({ default: false }) darkMode: boolean
  @Prop({ default: false }) bordered: boolean
  @Prop({ default: false }) onHeader: boolean
}
