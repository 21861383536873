
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FloatingMenuEvents, FloatingMenuItem } from '@/models';
import { EventBus } from '@/utils';

const { INPUT } = FloatingMenuEvents;

@Component({
  name: 'InputType',
})
export default class InputType extends Vue {
 @Prop() data: FloatingMenuItem

 public search({ target }: InputEvent) {
   const { value } = target as HTMLInputElement;
   EventBus.$emit(INPUT, value);
   this.$emit('change', { event: INPUT, value });
 }
}
