var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data.name)?_c('router-link',{staticClass:"link-type",class:{
    'link-type--dark': _vm.darkMode,
    'link-type--bordered': _vm.bordered,
    'link-type--on-header': _vm.onHeader,
  },attrs:{"to":{
    name: _vm.data.name,
    params: _vm.data.params,
  }}},[_vm._v(_vm._s(_vm.data.text))]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }