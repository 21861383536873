
import { Component, Prop, Vue } from 'vue-property-decorator';

import CheckboxInput from '@/components/shared/CheckboxInput.vue';

import { FloatingMenuItem, TextAlignment } from '@/models';

import { EventBus } from '@/utils';

const { LEFT } = TextAlignment;

@Component({
  name: 'CheckboxType',
  components: {
    CheckboxInput,
  },
})
export default class CheckboxType extends Vue {
  @Prop() data: FloatingMenuItem
  @Prop({ default: false }) darkMode: boolean
  @Prop({ default: false }) bordered: boolean
  @Prop({ default: false }) onHeader: boolean

  get textAlign() {
    return this.data.textAlign ?? LEFT;
  }

  public emitAction(checked: boolean, id: number) {
    if (this.data.id === id) {
      this.data.checked = checked;
      const event = this.data.action as string;
      const { keyString } = this.data;
      const itemToEmit = {
        keyString, item: this.data,
      };
      EventBus.$emit(event, itemToEmit);
      this.$emit('change', { event, item: this.data });
    }
  }
}
