var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"floating-menu-wrapper",class:{
    'floating-menu-wrapper--hover': _vm.menu.hover && _vm.show,
    'floating-menu-wrapper--show': _vm.show
  },on:{"mouseenter":_vm.openMenu,"mouseleave":_vm.closeMenu}},[_c('div',{staticClass:"floating-menu__trigger-element",on:{"click":function($event){return _vm.toggleMenu(_vm.menu.hover, $event)}}},[_vm._t("default")],2),_c('div',{staticClass:"floating-menu__list",class:{
        'floating-menu__list--dark': _vm.menu.darkMode,
        'floating-menu__list--right': _vm.menu.position === _vm.RIGHT,
        'floating-menu__list--left': _vm.menu.position === _vm.LEFT,
        'floating-menu__list--has-tip': _vm.menu.tip,
      }},[_vm._l((_vm.menu.data),function(item,index){return _c(_vm.getContent(item),{key:index,tag:"component",attrs:{"data":item,"show":_vm.show,"darkMode":_vm.menu.darkMode,"bordered":_vm.menu.bordered,"keyString":_vm.menu.keyString},on:{"change":function($event){return _vm.emitChange(index, _vm.menu.keyString, $event)}}})}),(_vm.menu.tip)?_c('div',{staticClass:"floating-menu__tip",class:{
        'floating-menu__tip--dark': _vm.menu.darkMode,
        'floating-menu__tip--right': _vm.menu.position === _vm.RIGHT,
        'floating-menu__tip--left': _vm.menu.position === _vm.LEFT,
      }}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }