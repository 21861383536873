
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FloatingMenuItem } from '@/models';
import { EventBus } from '@/utils';

@Component({
  name: 'SimpleType',
})
export default class SimpleType extends Vue {
  @Prop() data: FloatingMenuItem
  @Prop({ default: false }) darkMode: boolean
  @Prop({ default: false }) bordered: boolean
  @Prop({ default: false }) onHeader: boolean

  public emitAction(clickedItem: FloatingMenuItem) {
    EventBus.$emit(clickedItem.action as string, clickedItem);
  }
}
